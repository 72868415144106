html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
}
* {
  box-sizing: border-box;
}
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  position: relative;
}

@media (min-width: 1023px) {
  body {
    background: url("/images/bbg.png") no-repeat center center fixed;
    background-size: cover;
    transition: all 5s;
  }
}
